import React, {useState} from "react";
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";

function Signup() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phoneNumber: "",
        zipCode: "",
        username: "",
        password: "",
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false); // Track error state
    const navigate = useNavigate(); // For navigation

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const payload = {
                body: JSON.stringify(formData),
            };

            console.log("Constructed Payload:", payload);

            const response = await fetch(
                "https://ytmr6ow7re.execute-api.us-east-1.amazonaws.com/dev/signup",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

            if (response.ok) {
                setMessage("Signup successful! Redirecting to the home page...");
                setError(false);

                // Redirect to the home page after 2 seconds
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                const errorData = await response.json();
                setMessage(errorData.error || "Signup failed. Please try again.");
                setError(true);
            }
        } catch (err) {
            console.error("Error:", err);
            setMessage("Error connecting to server. Please try again.");
            setError(true);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                maxWidth: 500,
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 5,
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                bgcolor: "background.paper",
            }}
        >
            <Typography variant="h5" color="primary" align="center">
                Signup
            </Typography>

            <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
            />
            <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
            />
            <FormControl>
                <FormLabel>Gender</FormLabel>
                <RadioGroup
                    row
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>
            <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            <TextField
                label="Phone Number"
                type="tel"
                variant="outlined"
                fullWidth
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                required
            />
            <TextField
                label="Zip Code"
                type="text"
                variant="outlined"
                fullWidth
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                required
            />
            <TextField
                label="Username"
                variant="outlined"
                fullWidth
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
                Sign Up
            </Button>

            {/* Display success or error messages */}
            {message && (
                <Alert
                    severity={error ? "error" : "success"}
                    sx={{mt: 2, textAlign: "center"}}
                >
                    {message}
                </Alert>
            )}
        </Box>
    );
}

export default Signup;
