import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#3f51b5", // Muted blue
        },
        secondary: {
            main: "#f50057", // Soft pink
        },
        background: {
            default: "#f5f5f5", // Light gray background
            paper: "#ffffff", // White for paper elements
        },
        text: {
            primary: "#333333", // Dark gray for primary text
            secondary: "#666666", // Medium gray for secondary text
        },
    },
    typography: {
        fontFamily: "Roboto, Arial, sans-serif",
    },
});

export default theme;
