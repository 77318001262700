import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Parents from "./pages/Parents";
import Teachers from "./pages/Teachers";
import Students from "./pages/Students";
import Admins from "./pages/Admins";
import Signup from "./pages/Signup";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Navbar/>
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/parents" element={<Parents/>}/>
                        <Route path="/teachers" element={<Teachers/>}/>
                        <Route path="/students" element={<Students/>}/>
                        <Route path="/admins" element={<Admins/>}/>
                        <Route path="/signup" element={<Signup />} />
                    </Routes>
                </main>
            </Router>
        </ThemeProvider>
    );
}

export default App;
