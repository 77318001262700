import React, {useState} from "react";
import {
    AppBar,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };

    const navLinks = [
        {label: "Home", path: "/"},
        {label: "Parents", path: "/parents"},
        {label: "Teachers", path: "/teachers"},
        {label: "Students", path: "/students"},
        {label: "Admins", path: "/admins"},
    ];

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography
                    variant="h6"
                    component={Link}
                    to="/"
                    sx={{
                        flexGrow: 1,
                        textDecoration: "none",
                        color: "inherit",
                    }}
                >
                    Community Portal
                </Typography>

                {isMobile ? (
                    // Mobile: Show hamburger menu
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <List sx={{width: 250}}>
                                {navLinks.map((link) => (
                                    <ListItem
                                        button
                                        key={link.label}
                                        component={Link}
                                        to={link.path}
                                        onClick={toggleDrawer(false)}
                                    >
                                        <ListItemText primary={link.label}/>
                                    </ListItem>
                                ))}
                            </List>
                        </Drawer>
                    </>
                ) : (
                    // Desktop: Show buttons
                    navLinks.map((link) => (
                        <Button
                            key={link.label}
                            color="inherit"
                            component={Link}
                            to={link.path}
                        >
                            {link.label}
                        </Button>
                    ))
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
