import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Home() {
    const {t} = useTranslation(); // Access translation function

    return (
        <Box sx={{p: 5, textAlign: "center"}}>
            <Typography variant="h3" gutterBottom>
                {t("welcome")}
            </Typography>
            <Typography variant="h6" gutterBottom>
                {t("explore")}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/signup"
                sx={{mt: 3}}
            >
                {t("signup")}
            </Button>
        </Box>
    );
}

export default Home;
