import React from "react";
import {Box, Typography} from "@mui/material";

function Students() {
    return (
        <Box sx={{p: 5}}>
            <Typography variant="h4" gutterBottom>
                Students Section
            </Typography>
            <Typography>
                This section is under development. Please check back later.
            </Typography>
        </Box>
    );
}

export default Students;
