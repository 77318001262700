import React from "react";
import {Box, Typography} from "@mui/material";

function Admins() {
    return (
        <Box sx={{p: 5}}>
            <Typography variant="h4" gutterBottom>
                Admins Section
            </Typography>
            <Typography>
                Poll creation and other admin functionalities will go here.
            </Typography>
        </Box>
    );
}

export default Admins;
